import './SildeCard.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { StreamSliderData } from '../../sections/Data/StreamData';
import { FaDownload, FaStar } from 'react-icons/fa';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
};

const SildeCard = (p) => {
    return (

        <div className="slide-container">
            <Slider {...settings}>
                {StreamSliderData.map((data, index) => (
                    <div key={index}>
                        <div className='item'>
                            <div className='image-stream'>
                                <img src={data.image} className='img-stream' alt='' />
                            </div>
                            <div className='caption'>
                                <div className='d-flex justify-content-between'>
                                    <h6 className='name'> {data.name} </h6>
                                    <span> <FaStar className='star-icon' /> {data.rate} </span>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <p className='download'> Downloads </p>
                                    <span > <FaDownload className='download-icon' /> {data.download} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )

}

export default SildeCard