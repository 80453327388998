import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Container } from './component/index'
import { Header, Footer } from './sections/index'
import { Home, Browse, Profile, Details, Stream } from './Pages/index'


const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Container>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/details' element={<Details />} />
            <Route path='/browse' element={<Browse />} />
            <Route path='/stream' element={<Stream />} />
          </Routes>
        </Container>
        <Footer />
      </Router>
    </>
  )
}

export default App